:root {
  --bg-red: #ff5c5c;
  --bg-blue: #413bff;
  --font-color-white: #ffffff;
}

* {
  padding: 0;
  margin: 0;
  }

html {
  padding: env(safe-area-inset);
}

.App {
  position: relative;
  text-align: center;
  height: 100vh;
  background-color: #282c34;
  color: var(--font-color-white);
  width: 100vw;
  width: -webkit-fill-available;
}

.container-score {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  user-select: none;
}

.container-flip {
  flex-direction: row-reverse !important;
}

.container-team {
  flex: 1;
  display:flex;
  flex-direction:column;
}

.container-team-control {
}

.container-team-button {
  padding: 10px 30px;
  font-size: 30px;
}


.container-control {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-control-button {
  padding: 20px 0 20px;
  font-size: 30px;
  line-height: 16px;
}

.container-switch {
  position: absolute;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-switch-control {
  height: 60vh;
  width: 30vw;
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow:2px 3px 5px rgba(0,0,0,0.2);
  font-size: 20px;
  color: #404040;
  gap: 20px;
}

.input-switch {
  text-align: center;
  font-size: 16px;
  width: 50px;
}

/* CSS */
.button-switch {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 16px;
  height: 30px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 80px;
}

.button-switch:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.switch-now {
  color: #00ff99;
  animation: myAnim 2s ease 0s 1 normal forwards;
  animation-iteration-count: infinite;
}

@keyframes myAnim {
	0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		transform: scale3d(0.95, 1.05, 1);
	}

	75% {
		transform: scale3d(1.05, 0.95, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes floating {
	0% {
		transform: translateX(-10%) rotate(5deg);
	}
25% {
  transform: translateX(0%) rotate(-5deg);
}
	50% {
		transform: translateX(+10%) rotate(5deg);
	}
75% {
  transform: translateX(0%) rotate(-5deg);
}
	100% {
		transform: translateX(-10%) rotate(5deg);
	}
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg) scale(3); }
  50% { transform: rotate(0eg) scale(4); }
  75% { transform: rotate(-5deg) scale(3); }
  100% { transform: rotate(0deg); }
}

#container-left {
  background-color: var(--bg-blue);
}

#container-right {
  background-color: var(--bg-red);
}

@media only screen and (max-width: 1000px) {
  .container-score {
    height: 100vh;
  }

  .container-team {
    justify-content: center;
  }

  h2 {
    font-size: 100px;
    margin: 20px 0 50px;
  }
}

@media screen and (max-width:450px) {
  .container-score {
    flex-direction: column;
  }

  .container-control {
    flex-direction: row;
  }

  .container-flip {
    flex-direction: column-reverse !important;
  }

  .container-control-button {
    padding: 10px 10px 5px;
  }

}
